import React, { useContext, useState, useEffect } from "react";
import axios from "axios";

const AuthContext = React.createContext();

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {
    const backendUrl = "http://127.0.0.1:8000";
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(false);

    const login = async (email, password) => {
        setLoading(true);
        try {
            const response = await axios.post(`${backendUrl}/api/login/`, {
                email: email,
                password: password
            }, {
                withCredentials: true
            });
            setCurrentUser(response.data);
            setLoading(false);
            return response.data;
        } catch (error) {
            console.error('Login failed:', error);
            setLoading(false);
            throw error;
        }
    };

    const logout = () => {
        setLoading(true);
        axios.get(`${backendUrl}/api/logout`, { withCredentials: true })
            .then((res) => {
                console.log('inside logout', res.data)
                setCurrentUser(null)
            })
            .catch((err) => {
                console.error(err)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        setLoading(true);
        axios.get(`${backendUrl}/api/get_user_info`, { withCredentials: true })
            .then((res) => {
                console.log('inside authContext useEffect', res.data.data)
                setCurrentUser(res.data.data)
            })
            .catch((err) => {
                console.error(err)
                setCurrentUser(null)
            })
            .finally(() => {
                setLoading(false);
            })
    }, [])

    const value = {
        currentUser,
        login,
        logout,
        loading,
    };

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    );
}
