import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';
import '@fortawesome/fontawesome-free/css/all.min.css';
import image1 from './images/AUP header.jpg';
import image2 from './images/Logo.png';
import xLogo from './images/X-logo.png'; // Import the new X logo

function App() {
  const [showPopup, setShowPopup] = useState(false);
  return (
    <div className="bg-gray-100 min-h-screen"> 
      <div className="w-full bg-gray-100 rounded-lg">
        {/* Use hidden and block classes to show/hide images based on screen size */}
        <img src={image1} alt="AUP Australian Punjabi Channel" className="hidden lg:block w-full object-cover" />
        <img src={image2} alt="AUP Australian Punjabi Channel" className="block lg:hidden w-60 object-cover mx-auto pt-4" />
      </div>
      <div className="flex flex-col items-center min-h-screen bg-gray-100 mt-24">
        <div className="flex space-x-6 mt-8">
          <a href="https://www.facebook.com/AUPChannel/" target="_blank" rel="noopener noreferrer" className="text-5xl md:text-6xl text-blue-600 hover:text-blue-800 transition duration-300">
            <FontAwesomeIcon icon={faFacebook} />
          </a>
          <a href="https://www.instagram.com/aup_channel" target="_blank" rel="noopener noreferrer" className="text-5xl md:text-6xl text-pink-500 hover:text-pink-700 transition duration-300">
            <FontAwesomeIcon icon={faInstagram} />
          </a>
          <a href="https://www.youtube.com/@AustralianPunjabiAUPChannel" target="_blank" rel="noopener noreferrer" className="text-5xl md:text-6xl text-red-600 hover:text-red-800 transition duration-300">
            <FontAwesomeIcon icon={faYoutube} />
          </a>
          <a href="https://www.x.com/AupChannel" target="_blank" rel="noopener noreferrer" className="text-5xl md:text-6xl text-blue-400 hover:text-blue-600 transition duration-300">
            <img src={xLogo} alt="X logo" className="h-11 w-11 md:w-14 md:h-14 mt-1" /> {/* Use the new X logo */}
          </a>
          {/* <a href="https://www.tiktok.com" target="_blank" rel="noopener noreferrer" className="text-4xl text-black hover:text-gray-700 transition duration-300">
            <FontAwesomeIcon icon={faTiktok} />
          </a> */}
        </div>
      </div>
    </div>
  );
}

export default App;
